
import { ElMessageBox } from 'element-plus'
import { computed, defineComponent, reactive, h, ref, toRefs, VNode, isVNode, ComponentPublicInstance, RenderFunction } from 'vue'
import { VoidFunction } from '../basic'

interface ResolveCallback {
  (value: any): void | Promise<any>
}

interface DialogCallback<T> {
  (action: ActionType, el: T): void | Promise<any>
}

const enum Action {
  confirm = 'confirm',
  cancel = 'cancel',
}

export type ActionType = 'confirm' | 'cancel'

interface ResolveValue<T = any> {
  (callback: DialogCallback<T>): void | Promise<any>
}

export default defineComponent({
  name: 'BasicDialog',

  components: {
    contentNode: {
      render() {
        const { content } = this.$attrs
        if (isVNode(content)) return h(content, { ref: 'content' })
        return content
      },
    },
  },

  inheritAttrs: false,

  props: {
    text: String,
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: '取消',
    },
    confirmButtonText: {
      type: String,
      default: '确定',
    },
    loadingText: {
      type: String,
      default: '处理中,请稍候',
    },
    isCheck: {
      type: Boolean,
      default: true,
    },
    onConfirm: Function,
    onClose: Function,
  },

  emits: ['mounted'],

  setup(props, { attrs }) {
    interface State {
      pending: boolean
      visible: boolean
      disabled: boolean
      title?: string
      content?: VNode | null
    }
    const state = reactive<State>({
      pending: false,
      visible: false,
      disabled: false,
      title: '',
      content: null,
    })

    const buttonText = computed(() => (state.pending ? props.loadingText : props.confirmButtonText))

    let opendCallback: ResolveCallback | null = null

    function open<T = any>(e?: any, title?: string) {
      state.visible = true
      state.content = e
      state.title = title
      return new Promise<ResolveValue<T>>((resolve) => {
        opendCallback = resolve
      })
    }

    async function beforeClose(done: VoidFunction) {
      if (state.pending) return ElMessageBox.alert('正在处理中，请勿关闭窗口！')
      if (props.onClose) await props.onClose()
      state.content = null
      done()
    }

    function close() {
      if (opendFn) {
        opendFn = null
      }
      beforeClose(() => {
        state.pending = state.visible = false
        setCallback(Action.cancel)
      })
    }

    let opendFn: DialogCallback<any> | null = null

    const node = ref<ComponentPublicInstance | null>(null)
    function getContent() {
      const content = node.value?.$refs?.content
      return content as VNode
    }

    async function setCallback(action: ActionType) {
      if (opendCallback) {
        await new Promise((resolve) => {
          opendCallback &&
            opendCallback((callback: ResolveCallback) => {
              opendFn = callback
              if (opendFn) {
                resolve(opendFn(action, getContent()))
              }
            })
          // open函数的resolve方法只执行一次
          opendCallback = null
        })
      }
    }

    async function confirm() {
      state.pending = true
      // 执行open方法回调
      try {
        if (opendFn) await opendFn(Action.confirm, getContent())
        await setCallback(Action.confirm)
      } catch (error) {
        state.pending = false
        throw error
      }
      if (props.onConfirm) {
        try {
          await props.onConfirm()
          state.pending = false
          close()
        } catch (error) {
          state.pending = false
          throw error
        }
      } else {
        state.pending = false
        close()
      }
    }

    return {
      ...toRefs(state),
      buttonText,
      open,
      beforeClose,
      close,
      confirm,
      attrs,
      node,
    }
  },
})
