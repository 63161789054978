import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'admin',
        redirect: '/login',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/components/Layout/View.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "admin" */ '../views/admin/login/index.vue'),
            },
            {
                path: 'home',
                name: 'home',
                component: () => import('../views/admin/dataManage/home.vue')
            },
            {
                path: 'admin',
                name: 'layout',
                component: () => import('../views/admin/components/Layout/index.vue'),
                redirect: '/admin/dataManage',
                children: [
                    {
                        path: 'land', // 地快属性测评
                        name: 'land',
                        component: () => import('../views/admin/dataManage/land.vue'),
                        meta: {
                            title: '地块属性测评',
                            isMenu: true,
                            tips: 'Land Parcel Attribute Evaluation'
                        }
                    },
                    {
                        path: 'customer', // 客群属性测评
                        name: 'customer',
                        component: () => import('../views/admin/dataManage/customer.vue'),
                        meta: {
                            title: '客群属性测评',
                            isMenu: true,
                            tips: 'Customer Segment Attribute Evaluation'
                        }
                    },
                    {
                        path: 'people', // 指定人群测评
                        name: 'people',
                        component: () => import('../views/admin/dataManage/people.vue'),
                        meta: {
                            title: '指定人群测评',
                            isMenu: true,
                            tips: 'Designated Population Evaluation'
                        }
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
